<template>
<div>
    <data-preparation-overlay v-if="loading"></data-preparation-overlay>
    <v-container v-else>
        <v-row>
            <v-icon @click="redirectTo('SingleStorePage', { id: currentShop.id })">keyboard_arrow_left</v-icon> Back
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-alert color="warning text-center">Under Development</v-alert>
            </v-col>
            <v-col cols="5">
                <v-card class="pa-3">
                    <v-card-text @click="getCurrentPlan()">
                        <v-badge
                            :content="'Platform: '+platform"
                        >
                        </v-badge>
                    </v-card-text>
                    <v-form
                        ref="customPlanForm"
                        class="pl-5 pr-5"
                    >
                        <v-card-title class="pl-0">Custom Plan</v-card-title>
                        <v-switch
                            v-model="formData.test"
                            label="is test charge"
                        >
                        </v-switch>
                        <v-text-field
                            label="Plan Name"
                            required
                            v-model="formData.plan_name"
                        ></v-text-field>
                        <v-text-field
                            label="Description"
                            required
                            v-model="formData.description"
                        ></v-text-field>
                        <v-text-field
                            label="Price Per Month (USD)"
                            required
                            v-model="formData.price_per_month"
                        ></v-text-field>
                        <v-text-field
                            label="Product Limit"
                            required
                            v-model="formData.sync_product_limit"
                        ></v-text-field>
                        <v-text-field
                            label="Trial Duration (Day)"
                            required
                            v-model="formData.trial_duration"
                        ></v-text-field>

                        <v-card-title class="pl-0">Module Options</v-card-title>
                        <div cols="12" 
                            class="ma-2 "
                            v-for=" userModule in availableModules" :key="userModule.key"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="btn-border text-left pl-0"
                                        outlined
                                        width="100%"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-card-text>
                                            {{ userModule.title }}
                                        </v-card-text>
                                        <v-switch
                                            v-model="userModule.enabled"
                                            color="blue darken-1"   
                                        ></v-switch>
                                    </v-btn>
                                </template>
                                <span>
                                    {{ userModule.description }}
                                    <div v-if="userModule.enabled && userModule.installed_at != null">
                                        <v-badge 
                                            color="blue"
                                            :content="userModule.installed_at"
                                        ></v-badge>
                                    </div>
                                </span>
                            </v-tooltip>
                        </div>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="createCustomPlan()"
                                class="pl-5 pr-5"
                                color="primary"
                            >Submit</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
            <v-col cols="7">
                <v-card v-if="currentPlan != null">
                    <v-card-title>Current Plan</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        Plan Name 
                                        <strong v-if="currentPlan.test" class="text yellow pa-2">Test</strong>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>{{ currentPlan.plan_definition.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Description</v-list-item-subtitle>
                                    <v-list-item-title>{{ currentPlan.plan_definition.description }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Price Per Month (USD)</v-list-item-subtitle>
                                    <v-list-item-title>{{ currentPlan.plan_definition.price_per_month }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Product Limit</v-list-item-subtitle>
                                    <v-list-item-title>{{ currentPlan.plan_definition.sync_product_limit }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Trial Duration (Day)</v-list-item-subtitle>
                                    <v-list-item-title>{{ currentPlan.plan_definition.trial_duration }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Created At</v-list-item-subtitle>
                                    <v-list-item-title>{{ currentPlan.created_at }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-card>
                <v-card-title>Raw Data</v-card-title>
                <v-card-text class="dialog-raw-data">
                    <pre>{{ platformChargeRaw }}</pre>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</div>
</template>
<script>
import { mapState } from "vuex";
import { FETCH_AVAILABLE_MODULES, FETCH_USER_CURRENT_PLAN, CREATE_CUSTOM_PLAN, FETCH_CURRENT_SHOP } from "@/store/actions.type";
import DataPreparationOverlay from '../../components/DataPreparationOverlay.vue';

export default {
    name: "CustomPlanManagementPage",
    data() {
        return  {
            loading: false,
            formData: {
                plan_name: null,
                addons: [],
            },
            platformChargeRaw: null,
        };
    },
    created() {
        this.getCurrentPlan();
    },
    components: { 
        DataPreparationOverlay,
    },
    computed: {
        ...mapState('users', [ "user", "availableModules" ]),
        ...mapState('shops', [ "currentShop" ]),
        ...mapState('plans', ['currentPlan']),
        platform() {
            if (this.currentShop != null && typeof(this.currentShop.platform) != 'undefined') {
                return this.currentShop.platform;
            }
            return null;
        }
    },
    watch: {
        currentShop(newValue, oldValue) {
            if (newValue != oldValue && newValue != null) {
                if (this.availableModules == null && this.currentShop != null) {
                    let shopUserId = this.currentShop.user_id
                    this.$store.dispatch(`users/${FETCH_AVAILABLE_MODULES}`, shopUserId)
                }
            }
        }
    },
    methods: {
        redirectTo(routeName, param) {
            this.$router.push({name: routeName, param: param});
        },
        async getCurrentPlan() {
          await this.$store.dispatch(`shops/${FETCH_CURRENT_SHOP}`, this.$route.params.id);
          this.loading = true;
          this.$store.dispatch(`plans/${FETCH_USER_CURRENT_PLAN}`, this.currentShop.user.id).then((data) => {
            if (data.success) {
              this.platformChargeRaw  = data.plan.platform_charge_raw
            }
          }).finally(() => {
            this.loading = false;
          });
        },
        async createCustomPlan() {
          this.loading = true;
          this.formData.addons = [];
          this.availableModules.forEach((addon) => {
            if (addon.enabled) {
              this.formData.addons.push(addon.id);
            }
          });
          await this.$store.dispatch(`plans/${CREATE_CUSTOM_PLAN}`, {
            userId: this.currentShop.user.id,
            data: this.formData
          }).then((data)=> {
            if (data.success) {
              alert('Custom plan successfully created!');
            }
          }).catch(() => {
            this.loading = false;
          }).finally(() => {
            this.loading = false;
          });

          this.getCurrentPlan();
        },
    }
}
</script>
<style lang="scss">
.dialog-raw-data {
    overflow-y: scroll;
}    
</style>